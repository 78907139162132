import React from 'react';
import './App.css';
import {Outlet} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import {
    MC_Backend,
    MCDevicesContext, MCIssuesContext,
    MCOrganizationsContext,
    MCOverviewContext,
    MCRecentAlertsContext,
    MCUsersContext
} from "./common/MC_Backend";
import ErrorBoundary from "./common/ErrorBoundary";
import whiteLogo from "./white_logo.png";
import ScrollToTop from "./common/ui/misc/ScrollToTop";

// Create context
export const OverviewContext = React.createContext<MCOverviewContext>({
    overview: null, loading: false, errMsg: null, refreshTS: null
});
export const RecentAlertsContext = React.createContext<MCRecentAlertsContext>({
    recentAlerts: null, loading: false, errMsg: null, refreshTS: null
});
export const DevicesContext = React.createContext<MCDevicesContext>({
    devices: null, loading: false, errMsg: null, refreshTS: null
});
export const OrganizationsContext = React.createContext<MCOrganizationsContext>({
    organizations: null, loading: false, errMsg: null, refreshTS: null
});
export const UsersContext = React.createContext<MCUsersContext>({
    users: null, loading: false, errMsg: null, refreshTS: null
});
export const IssuesContext = React.createContext<MCIssuesContext>({
    issues: null, loading: false, errMsg: null, refreshTS: null
});

function App() {
    // Backend ref
    let backend = MC_Backend.getInstance();

    // State
    const [checkingCachedUser, setCheckingCachedUser] = React.useState<boolean>(true);
    const [overviewCtx, setOverviewCtx] = React.useState<MCOverviewContext>(backend.overviewCtx);
    const [recentAlertsCtx, setRecentAlertsCtx] = React.useState(backend.recentAlertsCtx);
    const [devicesCtx, setDevicesCtx] = React.useState(backend.devicesCtx);
    const [orgsCtx, setOrgsCtx] = React.useState(backend.orgsCtx);
    const [usersCtx, setUsersCtx] = React.useState(backend.usersCtx);
    const [issuesCtx, setIssuesCtx] = React.useState(backend.issuesCtx);
    backend.setOverviewContextState = setOverviewCtx;
    backend.setRecentAlertsContextState = setRecentAlertsCtx;
    backend.setDevicesContextState = setDevicesCtx;
    backend.setOrganizationsContextState = setOrgsCtx;
    backend.setUsersContextState = setUsersCtx;
    backend.setIssuesContextState = setIssuesCtx;

    // Check cached user
    React.useEffect(() => {
        backend.attemptSignInCachedUser().finally(() => {
            setCheckingCachedUser(false);
        });
    }, [backend]);

    // Theme
    const universalTheme = createTheme({
        spacing: 8,
        palette: {
            primary: {
                light: '#2f2f2c99',
                main: "#2f2f2cde",
                dark: '#2f2f2cfc',
                contrastText: '#fff',
            },
            secondary: {
                light: '#d88a2899',
                main: "#d88a28de",
                dark: '#d88a28fc',
                contrastText: '#fff',
            }
            /*
            info: 0,
            success: 0,
            error: 0,
            warning: 0
            */
        }
    });
    return (
      <ThemeProvider theme={universalTheme}>
          <ErrorBoundary>
              <OverviewContext.Provider value={overviewCtx}>
                  <RecentAlertsContext.Provider value={recentAlertsCtx}>
                      <DevicesContext.Provider value={devicesCtx}>
                          <OrganizationsContext.Provider value={orgsCtx}>
                              <UsersContext.Provider value={usersCtx}>
                                  <IssuesContext.Provider value={issuesCtx}>
                                      <div className="App">
                                          {/* Scroll to top on nav catcher */}
                                          {<ScrollToTop/>}
                                          {/* Splash screen */}
                                          {
                                              (checkingCachedUser) &&
                                              <div className={"splash-div"}>
                                                  <img src={whiteLogo} className={"splash-logo"} alt={"logo"} />
                                                  <span className={"splash-text"}>Loading...</span>
                                              </div>
                                          }
                                          {/* Router outlet for nested routes */}
                                          {
                                              (!checkingCachedUser) &&
                                              <Outlet/>
                                          }
                                      </div>
                                  </IssuesContext.Provider>
                              </UsersContext.Provider>
                          </OrganizationsContext.Provider>
                      </DevicesContext.Provider>
                  </RecentAlertsContext.Provider>
              </OverviewContext.Provider>
          </ErrorBoundary>
      </ThemeProvider>
    );
}

export default App;
