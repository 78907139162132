import {MC_Organization} from "./iot/MC_Organization";
import {CustomConstants} from "../custom/CustomConstants";
import {SimpleSubscription} from "./iot/simple_stripe_types";

export class MC_Constants {

    // JAS
    public static JAS_LINK: string = "https://jasmonitoring.com";

    // API
    public static API_ERR_MESSAGE_PROPERTY: string = "error_message";
    public static TZ_AMERICA_LOS_ANGELES: string = "America/Los_Angeles";
    public static TZ_AMERICA_DENVER: string = "America/Denver";
    public static TZ_AMERICA_CHICAGO: string = "America/Chicago";
    public static TZ_AMERICA_NEW_YORK: string = "America/New_York";
    public static ALL_SUPPORTED_TIME_ZONES: string[] = [
        this.TZ_AMERICA_LOS_ANGELES,
        this.TZ_AMERICA_DENVER,
        this.TZ_AMERICA_CHICAGO,
        this.TZ_AMERICA_NEW_YORK
    ];

    // Simplify JAS-XX-YY.Z.0001 -> 0001
    public static parseReadableJASID(jasID: string, id: string): string {
        // Use ID as default
        if (jasID.length === 0) {
            return id;
        }
        // Short JAS IDs, return the full things
        if (jasID.length <= 6) {
            return jasID;
        }
        // For long JAS IDs, break it apart
        const lastPeriodIndex: number = jasID.lastIndexOf(".");
        if (lastPeriodIndex !== -1 && (lastPeriodIndex < (jasID.length - 1))) {
            return jasID.substr(lastPeriodIndex + 1);
        } else {
            return jasID;
        }
    }

    public static getUSDStr(value: number | null, divideBy100 = true): string {
        if (value == null || isNaN(value)) {
            return "N/A";
        }
        // Parse value
        const usdVal: number = Number( (divideBy100) ? (value/100) : value);
        const positiveVal: number = (usdVal >= 0) ? usdVal : -1 * usdVal;
        const usdValStr: string = positiveVal.toFixed(2);
        // Create string
        return ((usdVal < 0) ? "-$" : "$") + usdValStr;
    }

    public static getOrgViewLink(orgID: string): string {
        return CustomConstants.MONITORING_SOFTWARE_URL + "/log_in?org_id=" + orgID;
    }

    public static getOrgInviteLink(org: MC_Organization): string {
        return CustomConstants.MONITORING_SOFTWARE_URL
            + "/create_account"
            + "?org_id=" + org.id
            + "&org_key=" + org.key;
    }

    // Format readable date
    public static getReadableDateStr(date: Date | null, withTime: boolean = true): string {
        if (date != null) {
            let str: string = this.getMonthStr(date.getMonth()) + " " + date.getDate() + ", " + date.getFullYear();
            if (withTime) {
                let hours = date.getHours();
                let min = date.getMinutes();
                let hourStr = (hours === 0)
                    ? "12"
                    : ((hours > 12) ? hours - 12 : hours)
                ;
                let minStr = (min < 10) ? "0" + min : "" + min;
                str += ", " + hourStr + ":" + minStr;
                str += (hours > 11) ? " PM" : " AM";
            }
            return str;
        }
        return "N/A";
    }

    private static getMonthStr(index: number): string {
        switch (index) {
            case 0: return "Jan";
            case 1: return "Feb";
            case 2: return "Mar";
            case 3: return "Apr";
            case 4: return "May";
            case 5: return "Jun";
            case 6: return "Jul";
            case 7: return "Aug";
            case 8: return "Sep";
            case 9: return "Oct";
            case 10: return "Nov";
            case 11: return "Dec";
        }
        return "?";
    }

    // Turn long strings into "xyz..."
    public static limitStr(str: string, maxLength: number, fromStart: boolean = true): string {
        // Return full string
        if (str.length <= maxLength) {
            return str;
        }
        // str is too long
        if (fromStart) {
            // Substring from the start
            return str.substr(0, maxLength) + "...";
        } else {
            // Substring from the end
            return "..." + str.substr(str.length - maxLength);
        }
    }

    public static parseDate(dateStr: string | null | undefined): Date | null {
        if (dateStr == null) {
            return null;
        }
        let val: number = Date.parse(dateStr);
        return (isNaN(val)) ? null : new Date(val);
    }

    public static parseNullableField<FieldType>(data: any, field: string, defaultValue: FieldType): FieldType {
        if (data != null && data.hasOwnProperty(field)) {
            return data[field];
        }
        return defaultValue;
    }

    public static parseOrgIDFromSubscription(sub: SimpleSubscription): string | null {
        const md: any = sub.metadata;
        if (md != null && md.hasOwnProperty("organization_id")) {
            return md["organization_id"] as string;
        }
        return null;
    }

    public static parseDeviceIDsFromSubscription(sub: SimpleSubscription): string[] {
        const md: any = sub.metadata;
        if (md != null) {
            if (md.hasOwnProperty("device_ids")) {
                const deviceIDsStr: string = md["device_ids"] as string;
                return JSON.parse(deviceIDsStr) as string[];
            }
        }
        return [];
    }

}
