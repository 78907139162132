import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LogIn from "./gate/LogIn";
import AdminCore from "./core/AdminCore";
import Alerts from "./core/alerts/Alerts";
import Overview from "./core/overview/Overview";
import Settings from "./core/settings/Settings";
import Users from "./core/users/Users";
import Organizations from "./core/organizations/Organizations";
import Devices from "./core/devices/Devices";
import ViewUser from "./core/users/view_user/ViewUser";
import ViewOrganization from "./core/organizations/view_organization/ViewOrganization";
import { initializeApp } from "firebase/app";
import {MC_Backend} from "./common/MC_Backend";
import ViewDevice from "./core/devices/view_device/ViewDevice";
import Logs from "./core/logs/Logs";
import Support from "./core/support/Support";
import ViewIssue from "./core/support/view_issue/ViewIssue";
import {getAuth} from "@firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC7sVy3vBMTVMkVphQXrS-RHePEuEfl9qw",
    authDomain: "jas-brew.firebaseapp.com",
    projectId: "jas-brew",
    storageBucket: "jas-brew.appspot.com",
    messagingSenderId: "633558915728",
    appId: "1:633558915728:web:1f71de18f8693d326d5615"
};

// Init firebase & backend service
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// Local persistence is default
//auth.setPersistence(browserLocalPersistence).then(...);
MC_Backend.createInstance(auth);

// Render app
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              {/* Base App path "/" */}
              <Route path={"/"} element={<App/>}>

                  {/* Index (Redirect to Log In)*/}
                  <Route index element={<Navigate to={"log_in"}/>}/>

                  {/* Log In */}
                  <Route path={"log_in"} element={<LogIn/>}/>

                  {/* Core MC */}
                  <Route path={"core"} element={<AdminCore/>}>

                      {/* Index (Redirect to Overview)*/}
                      <Route index element={<Navigate to={"overview"}/>}/>

                      {/* Overview */}
                      <Route path={"overview"} element={<Overview/>}/>

                      {/* Devices */}
                      <Route path={"devices"}>

                          {/* "devices" Show device list */}
                          <Route index element={<Devices/>}/>

                          {/* "devices/id" show specific org */}
                          <Route path={":deviceID"} element={<ViewDevice/>}/>

                          {/* No route match (Redirect to blank orgs */}
                          <Route path="*" element={<Navigate to={"/core/devices"}/>}/>

                      </Route>

                      {/* Organizations */}
                      <Route path={"organizations"}>

                          {/* "organizations" Show org list */}
                          <Route index element={<Organizations/>}/>

                          {/* "organizations/id" show specific org */}
                          <Route path={":orgID"} element={<ViewOrganization/>}/>

                          {/* No route match (Redirect to blank orgs */}
                          <Route path="*" element={<Navigate to={"/core/organizations"}/>}/>

                      </Route>

                      {/* Users */}
                      <Route path={"users"}>

                          {/* "users" Show user list */}
                          <Route index element={<Users/>}/>

                          {/* "users/id" show specific user */}
                          <Route path={":uid"} element={<ViewUser/>}/>

                          {/* No route match (Redirect to blank users */}
                          <Route path="*" element={<Navigate to={"/core/users"}/>}/>

                      </Route>

                      {/* Support */}
                      <Route path={"support"}>

                          {/* "support" Show issues list */}
                          <Route index element={<Support/>}/>

                          {/* "support/id" show specific issue */}
                          <Route path={":issueID"} element={<ViewIssue/>}/>

                          {/* No route match (Redirect to blank support */}
                          <Route path="*" element={<Navigate to={"/core/support"}/>}/>

                      </Route>

                      {/* Alerts */}
                      <Route path={"alerts"} element={<Alerts/>}/>

                      {/* Logs */}
                      <Route path={"logs"} element={<Logs/>}/>

                      {/* Settings */}
                      <Route path={"settings"} element={<Settings/>}/>

                  </Route>

                  {/* No route match (Redirect to blank */}
                  <Route path="*" element={<Navigate to={"/"}/>}/>

              </Route>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
